<template>
    <v-card class="custom-card-position">
        <div class="pa-0 custom-button-position">
         <dropdown-menu
            :is-text="true"
            :can-close="true"
            icon="fas fa-ellipsis"
         >
           <template #listItems>
            <v-list>
                <v-list-item
                    class="item-hover px-0"
                    @click="editItem(item)"
                >
                    <v-btn text color="accent darken-4">
                        <v-icon size="12" class="mr-2">
                            fa-solid fa-pen
                        </v-icon>
                        <span class="table-menu-item">
                        {{
                           $t(
                                `Admin.dataTable.tableActions.edit`
                             )
                         }}
                        </span>
                    </v-btn>
                </v-list-item>
                <v-list-item
                    class="item-hover px-0"
                    @click="$emit('delete-item', item)"
                >
                    <v-btn text color="error lighten-1">
                        <v-icon size="12" class="mr-2">
                            fa-solid fa-trash
                        </v-icon>
                        <span class="table-menu-item">
                        {{
                           $t(
                               `Admin.dataTable.tableActions.delete`
                             )
                        }}
                        </span>
                    </v-btn>
                </v-list-item>
            </v-list>
          </template>
        </dropdown-menu>
        </div>
        <component
            :is="computedComponent.component"
            v-bind="computedComponent.props"
            class="text-decoration-none"
        >
            <v-img :src="item.image_url || require(`@/assets/newsletter/newsletter-card.png`)" height="200">
                <div class="image__overlay">
                    <div class="white--text mb-2 text-body-1">
                        {{ item.title }}
                        <!-- {{ item.title }} {{item.date && `- ${item.date}`}} -->
                    </div>
                    <div class="line primary mb-2" />
                </div>
            </v-img>
        </component>
    </v-card>
</template>

<script>
import DropdownMenu from "../components/DropdownMenu.vue";

export default {
    name: "NewsletterListCard",

    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    
    components: {
        DropdownMenu
    },

    computed: {
        computedComponent() {
            if (this.item.old_site_url) {
                return {
                    component: "a",
                    props: {
                        href: this.item.old_site_url,
                    },
                };
            }
            return {
                component: "router-link",
                props: {
                    to: {
                        name: "NewsletterUpdate",
                        params: { id: this.item.id },
                    },
                },
            };
        },
    },

    methods: {
        editItem(itemToEdit) {
            this.$router.push({name: "NewsletterUpdate", params: { id: itemToEdit.id } });
        }
    }
};
</script>

<style scoped>
.image__overlay {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: linear-gradient(
        to top,
        rgba(17, 19, 17, 1),
        rgba(17, 19, 17, 0.8) 20%,
        rgba(17, 19, 17, 0.438) 40%,
        transparent 75%,
        transparent
    );
}

.line {
    height: 3px;
    width: 30px;
    transition: width 0.2s ease-in-out;
}
a:hover .line {
    width: 70px;
}
.custom-card-position {
    position: relative;
}
.custom-button-position {
    position: absolute;
    background: #eee;   ;
    border-radius: 5px;
    border: none;
    z-index: 1;
    top: 0;
    right: 0;
    margin: 0.5rem;
}
</style>
