<template>
    <v-container class="my-10">
        <div class="d-flex">
            <v-btn
                icon
                color="accent darken-3"
                class="align-self-center"
                link
                @click="$router.replace({ name: 'NewsletterList' })"
            >
                <v-icon class="mr-2"> fa-solid fa-chevron-left </v-icon>
            </v-btn>
            <h2 class="main-heading primary--text">
                {{ $t("Admin.newsletter.form.editHeading") }}
            </h2>
            <v-spacer></v-spacer>

            <v-btn
                color="error darken-1"
                depressed
                class="align-self-center"
                link
                @click="openDeleteDialog()"
            >
                <v-icon x-small left>fa fa-trash</v-icon>
                Delete Article
            </v-btn>
        </div>

        <loading-spinner
            v-if="getNewsletterStatus_Pending || getNewsletterStatus_Idle"
            style="height: 400px"
        ></loading-spinner>
        <newsletter-form
            v-else-if="newsletterToEdit"
            :newsletter-to-edit="newsletterToEdit"
            :pending="updateNewsletterStatus_Pending"
            :validation="validationErrors"
            @submit-form="submitForm($event)"
        ></newsletter-form>
        <confirm-modal :open="deleteDialogOpen" :close.sync="deleteDialogOpen">
            <template #content>
                <span class="modal-content">
                    {{ $t(`Admin.newsletter.delete`) }}
                </span>
            </template>
            <template #action>
                <v-btn
                    depressed
                    dense
                    color="error lighten-1"
                    class="mr-2 delete-button-hover"
                    :loading="deleteItemStatus_Pending"
                    @click="confirmDelete()"
                >
                    {{ $t(`Profile.modal.confirm`) }}
                </v-btn>
            </template>
        </confirm-modal>
    </v-container>
</template>

<script>
// API layer Imports
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";
import {
    updateNewsletterArticle,
    getNewsletterArticle,
    deleteNewsletterArticle,
} from "@/api/adminApi.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import NewsletterForm from "./NewsletterForm.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default {
    name: "NewsLetterEdit",

    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },
    components: { NewsletterForm, LoadingSpinner, ConfirmModal },

    data() {
        return {
            validationErrors: [],
            newsletterToEdit: {},
            getNewsletterStatus: apiStatus.Idle,
            updateNewsletterStatus: apiStatus.Idle,
            deleteDialogOpen: false,
            deleteItemStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed([
            "getNewsletterStatus",
            "updateNewsletterStatus",
            "deleteItemStatus",
        ]),
    },

    methods: {
        async handleNewsletter() {
            this.getNewsletterStatus = apiStatus.Pending;

            const payload = {
                id: this.id,
            };

            const { response, error } = await withAsync(
                getNewsletterArticle,
                payload
            );

            if (error) {
                this.getNewsletterStatus = apiStatus.Error;
            }

            const { title, date, old_site_url } = response.data.data;

            this.newsletterToEdit = {
                title,
                date,
                old_site_url,
            };
            this.getNewsletterStatus = apiStatus.Success;
        },

        async submitForm(event) {
            this.updateNewsletterStatus = apiStatus.Pending;

            const payload = {
                id: this.id,
                data: event,
            };

            const { response, error } = await withAsync(
                updateNewsletterArticle,
                payload
            );

            if (error) {
                this.updateNewsletterStatus = apiStatus.Error;
                this.validationErrors = error.response.data.metadata.response_message;
                // console.log("validation errors when editing", this.validationErrors );
                return;
            }
            this.updateNewsletterStatus = apiStatus.Success;
            this.$router.replace({name: "NewsletterList"})
        },

        openDeleteDialog() {
            this.deleteDialogOpen = true;
        },
        async confirmDelete() {
            this.deleteItemStatus = apiStatus.Pending;

            const payload = {
                id: this.id,
            };

            const { response, error } = await withAsync(
                deleteNewsletterArticle,
                payload
            );

            if (error) {
                this.deleteItemStatus = apiStatus.Error;
            }

            this.deleteDialogOpen = false;
            this.deleteItemStatus = apiStatus.Success;
            this.$router.replace({ name: "NewsletterList" });
        },
    },

    created() {
        this.handleNewsletter();
    },
};
</script>
