<template>
    <v-dialog
        v-model="modal"
        width="400"
        persistent
    >
        <v-card>
            <v-card-title>
                <v-icon
                    dense
                    color="warning"
                    class="mr-2"
                >
                    fas fa-exclamation-triangle
                </v-icon>
                <h4 class="modal__heading">
                    {{ $t(`Admin.modal.areYouSure`) }}
                </h4>
            </v-card-title>

            <v-card-text>
                <slot name="content" />
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    depressed
                    dense
                    text
                    color="primary darken4"
                    class="mr-2"
                    @click="cancel()"
                >
                    {{ $t(`Admin.modal.cancel`) }}
                </v-btn>
                <slot name="action" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmModal",

    props: {
        open: {
            required: true,
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            modal: false,
        };
    },

    methods: {
        cancel() {
            this.modal = false;
            this.$emit("update:close", false);
        },
    },

    watch: {
        modal(newValue) {
            if (newValue == false) {
                this.$emit("update:close", false);
            }
        },
        open(newValue) {
            if (newValue == true) {
                this.modal = newValue;
            }
            this.modal = newValue;
        },
    },
};
</script>

<style lang="scss" scoped>
.modal__heading {
    font-size: 1em;
    font-weight: 500;
    color: var(--v-primary-darken2);
}
</style>
