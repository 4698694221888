<template>
    <v-container class="my-10">
        <div class="d-flex">
            <v-btn
                icon
                color="accent darken-3"
                class="align-self-center"
                link
                @click="$router.replace({ name: 'NewsletterList' })"
            >
                <v-icon class="mr-2"> fa-solid fa-chevron-left </v-icon>
            </v-btn>
            <h2 class="main-heading primary--text">
                {{ $t("Admin.newsletter.form.createHeading") }}
            </h2>
        </div>

        <newsletter-form :pending="createNewsletterStatus_Pending" :validation="validationErrors" @submit-form="submitForm($event)"></newsletter-form>
    </v-container>
</template>

<script>
// API layer Imports
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";
import { createNewsletterArticle } from "@/api/adminApi.js"

import TextEditor from "@/components/Inputs/TextEditor.vue";
import { required } from "vuelidate/lib/validators";
import NewsletterForm from "./NewsletterForm.vue";
export default {
    name: "NewletterCreate",

    validations: {
        newsletter: {
            title: { required },
            date: { required },
        },
    },

    components: {
        TextEditor,
        NewsletterForm,
    },

    data() {
        return {
            createNewsletterStatus: apiStatus.Idle,
            validationErrors: [],
        };
    },

    computed: {
        ...apiStatusComputed(["createNewsletterStatus"])
    },
    methods: {
        async submitForm(event) {
            this.createNewsletterStatus = apiStatus.Pending;

            const payload = event;
            const {response, error} = await withAsync(createNewsletterArticle, payload)
            
            if(error) {
                this.createNewsletterStatus = apiStatus.Error
                this.validationErrors = error.response.data.metadata.response_message;
                // console.log('validation errors', this.validationErrors);
                return;
            }

            this.createNewsletterStatus = apiStatus.Success;
            this.$router.replace({name: "NewsletterList"})
        },
    },
};
</script>

<style scoped>
.side__menu {
    max-height: 350px;
    overflow: auto;
}
.side__menu-item {
    background-color: var(--v-primary-base);
}
.side__menu-item:hover {
    cursor: pointer;
    background-color: grey;
}

.side__menu-item.active {
    background-color: grey;
}
</style>
