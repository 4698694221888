<template>
    <v-container class="my-10">
        <div class="d-flex align-center mb-5">
            <v-icon normal left color="purple">fa fa-newspaper</v-icon>
            <h2 class="main-heading primary--text flex-grow-1">
                {{ $t("Admin.newsletter.form.listHeading") }}
            </h2>
            <v-btn
                depressed
                dense
                outlined
                link
                :to="{ name: 'NewsletterCreate' }"
                color="primary"
                class="pa-7"
            >
                <v-icon size="14" class="mr-2"> fa-solid fa-plus </v-icon>
                {{ $t(`Admin.newsletter.createNew`) }}
            </v-btn>
        </div>

        <loading-spinner
            v-if="getNewsletterStatus_Pending || getNewsletterStatus_Idle"
            style="height: 400px"
        ></loading-spinner>

        <div v-else-if="getNewsletterStatus_Error">
            {{ $t(`Admin.newsletter.error`) }}
        </div>

        <div v-else-if="newsletterList.length === 0">
            {{ $t(`Admin.newsletter.noItems`) }}
        </div>

        <div v-else>
            <v-row>
                <v-col
                    v-for="item in newsletterList"
                    :key="item.id"
                    cols="12"
                    md="6"
                    lg="4"
                >
                    <newsletter-list-card :item="item" @delete-item="deleteItem($event)"></newsletter-list-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
// API layer Imports
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";
import { getNewsletterList, deleteNewsletterArticle } from "@/api/adminApi.js";

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import NewsletterListCard from "./NewsletterListCard.vue";

export default {
    name: "NewsletterList",

    components: {
        LoadingSpinner,
        NewsletterListCard,
    },

    data() {
        return {
            newsletterList: [],
            getNewsletterStatus: apiStatus.Idle,
            deleteNewsletterStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["getNewsletterStatus", "deleteNewsletterStatus"]),
    },

    methods: {
        async handleNewsletterList() {
            this.getNewsletterStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getNewsletterList);

            if (error) {
                this.getNewsletterStatus = apiStatus.Error;
            }

            this.newsletterList = response.data.data;
            // console.log("newsletter res here", response.data.data);
            this.getNewsletterStatus = apiStatus.Success;
        },
        async deleteItem(itemtoDelete) {
            this.deleteNewsletterStatus = apiStatus.Pending;

            const payload = {
                id: itemtoDelete.id,
            };

            const { response, error } = await withAsync(
                deleteNewsletterArticle,
                payload
            );

            if (error) {
                this.deleteNewsletterStatus = apiStatus.Error
                return;
            }

            this.deleteNewsletterStatus = apiStatus.Success;
            // this.$router.replace({ name: "NewsletterList" });
            this.handleNewsletterList();
        }
    },

    created() {
        this.handleNewsletterList();
    },
};
</script>


<style scoped>
</style>
