<template>
    <base-card>
        <template #content>
            <v-card-text>
                <div class="text-center d-flex flex-column align-center justify-center mb-10">
                <v-icon x-large color="purple">fa fa-newspaper</v-icon>
                <h2 class="main-heading text-h6 mb-1">Upload a Newsletter from the website</h2>
                <p>Select a newsletter from
                    <a
                     href="https://old.agrobofood.eu/newsletter-main/"
                     target="_blank"
                     class="primary--text website-link-hover"
                     >
                    here</a>, 
                    copy it's url and simply paste it in the field below..
                </p>
                </div>
                <form>
                    <v-row justify-md="center">
                        <v-col cols="12" md="8">
                            <v-text-field
                                v-model="newsletter.title"
                                dense
                                outlined
                                placeholder="E.g. Project Newsletter #1"
                                label="Title"
                                prepend-inner-icon="fas fa-t"
                                :error-messages="titleErrors"
                                @input="$v.newsletter.title.$touch()"
                                @blur="$v.newsletter.title.$touch()"
                            ></v-text-field>

                            <v-text-field
                                v-model="newsletter.date"
                                dense
                                outlined
                                placeholder="E.g. JANUARY 2023"
                                label="Date"
                                prepend-inner-icon="fas fa-calendar-days"
                                :error-messages="dateErrors"
                                @input="$v.newsletter.date.$touch()"
                                @blur="$v.newsletter.date.$touch()"
                            ></v-text-field>
                            <v-text-field
                                v-model="newsletter.link"
                                dense
                                outlined
                                label="Newsletter"
                                prepend-inner-icon="fa fa-link"
                                :error-messages="linkErrors"
                                @input="$v.newsletter.link.$touch()"
                                @blur="$v.newsletter.link.$touch()"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- <v-row class="align-self-stretch" justify="space-between">
                        <v-col cols="12" md="4" class="pt-0">
                            <h2 class="text-h5 text-center">Categories</h2>

                            <div ref="sideMenuRef" class="side__menu">
                                <draggable
                                    v-model="menuItems"
                                    @start="
                                        handleDragStart($event);
                                        drag = true;
                                    "
                                    @end="drag = false"
                                    @change="handleDraggableChange($event)"
                                >
                                    <div
                                        v-for="(item, idx) in menuItems"
                                        :key="idx"
                                        class="d-flex align-center"
                                    >
                                        <div
                                            class="side__menu-item px-5 py-2 my-2 text-uppercase white--text text-body-1 font-weight-bold rounded-xl flex-grow-1"
                                            :class="{
                                                active: idx == activeIdx,
                                            }"
                                            @click="changeMenu(idx)"
                                        >
                                            {{ item }}
                                        </div>
                                        <div>
                                            <v-btn
                                                icon
                                                color="error"
                                                @click="deleteMenuItem(idx)"
                                            >
                                                <v-icon size="18">
                                                    fas fa-trash
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </draggable>
                            </div>
                            <v-text-field
                                v-model="menuItemInput"
                                dense
                                outlined
                                rounded
                                placeholder="Add new category"
                                append-icon="fas fa-plus"
                                @click:append="addMenuItem()"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="8">
                            <div class="">
                                <text-editor
                                    id="newsletterEditor"
                                    v-model="activeContent"
                                    :label="`${activeMenu || ''} Content`"
                                    :current-value="activeContent"
                                ></text-editor>
                            </div>
                        </v-col>
                    </v-row> -->
                </form>
            </v-card-text>
            <v-divider class="my-5"></v-divider>
            <v-row class="align-center mr-4">
                <v-col
                 cols="12"
                 md="6"
                 sm="12"
                 class="d-flex justify-center justify-md-start mt-5"
                >
                 <ul v-if="validation.length != 0">
                    <div class="d-flex">
                        <v-icon small left color="warning">
                            fa fa-warning
                        </v-icon>
                        <h3 class="main-heading">
                            Form failed to Submit!
                        </h3>
                    </div>
                    <li
                     v-for="(error, index) in validation"
                     :key="index"
                     style="list-style: none;"
                     class="error--text text-sm-center"
                    >
                      {{ error.toString() }}
                    </li>
                 </ul>
                </v-col>
                <v-col
                 cols="12"
                 md="6"
                 sm="12"
                 class="d-flex justify-center justify-md-end mt-5"
                >
                 <v-card-actions class="justify-end">
                    <v-btn
                      depressed
                      color="primary"
                      :disabled="formInValid"
                      :loading="pending"
                      @click="submitForm()"
                    >
                      <v-icon x-small left>fa-solid fa-save</v-icon>
                        Submit
                      </v-btn>
                 </v-card-actions>
                </v-col>
            </v-row>
        </template>
    </base-card>
</template>

<script>
import TextEditor from "@/components/Inputs/TextEditor.vue";

import { required } from "vuelidate/lib/validators";

import draggable from "vuedraggable";

export default {
    name: "NewletterCreate",

    validations: {
        newsletter: {
            title: { required },
            date: { required },
            link: { required }
        },
    },

    props: {
        newsletterToEdit: {
            type: Object,
            required: false,
            default: () => null,
        },
        pending: {
            type: Boolean,
            required: false,
            default: false,
        },
        validation: {
            type: [Object, Array, String],
            required: false,
            default: null,
        }
    },

    components: {
        TextEditor,
        draggable,
    },

    data() {
        return {
            newsletter: {
                title: "",
                date: "",
                link: "",
            },

            menuItems: [],
            contentItems: [],

            activeIdx: null,
            activeMenu: "",
            activeContent: "",

            menuItemInput: "",
        };
    },

    computed: {
        formInValid() {
            return this.$v.$invalid;
            // return this.$v.$invalid || !this.contentItems[0];
        },
        titleErrors() {
            const errors = [];
            if (!this.$v.newsletter.title.$dirty) return errors;
            !this.$v.newsletter.title.required &&
                errors.push(this.$i18n.t(`ErrorMessages.required`));
            if (this.validation.title) {
                errors.push(this.validation.title[0])
            }
            return errors;
        },

        dateErrors() {
            const errors = [];
            if (!this.$v.newsletter.date.$dirty) return errors;
            !this.$v.newsletter.date.required &&
                errors.push(this.$i18n.t(`ErrorMessages.required`));
            if (this.validation.date) {
                errors.push(this.validation.date[0])
            }
            return errors;
        },
        linkErrors() {
            const errors = [];
            if (!this.$v.newsletter.link.$dirty) return errors;
            !this.$v.newsletter.link.required &&
                errors.push(this.$i18n.t(`ErrorMessages.required`));
            if (this.validation.link) {
                errors.push(this.validation.link[0])
            }
            const regExp = /^https?:\/\/old\.agrobofood\.eu\/newsletter\d+\/$/
            let isValidNewsletterUrl = regExp.test(this.newsletter.link);

            if (isValidNewsletterUrl == false && this.newsletter.link != '') {
                errors.push('This is not a valid newsletter URL. You may try another one');
            }
            return errors;
        }
    },
    methods: {
        changeMenu(idx) {
            this.contentItems[this.activeIdx] = this.activeContent;
            this.activeIdx = idx;
        },
        addMenuItem() {
            if(!this.menuItemInput) return;
            let name = this.menuItemInput.toUpperCase();

            this.menuItems.push(name);
            this.contentItems.push(`${name} content`);

            if (this.activeIdx !== null) {
                this.contentItems[this.activeIdx] = this.activeContent;
            }

            this.activeIdx = this.menuItems.length - 1;
            this.menuItemInput = "";

            let height = this.$refs.sideMenuRef.scrollHeight;

            //Use next tick to ensure the DOM is updated before the scroll happens
            this.$nextTick(() => {
                this.$refs.sideMenuRef.scroll({
                    top: height + 40,
                    behavior: "smooth",
                });
            });
        },

        deleteMenuItem(idx) {
            this.menuItems.splice(idx, 1);
            this.contentItems.splice(idx, 1);

            if (this.activeIdx === idx) {
                this.activeIdx = 0;
            } else {
                this.activeIdx =
                    this.activeIdx >= 1 ? this.activeIdx - 1 : null;
            }
        },

        createPayload() {
            const sections = this.menuItems.reduce((acc, curr, idx) => {
                acc.push({
                    title: curr,
                    content: this.contentItems[idx],
                });
                return acc;
            }, []);
            let payload = {
                ...this.newsletter,
                sections,
            };
            return payload;
        },

        submitForm() {
            this.contentItems[this.activeIdx] = this.activeContent;
            // const payload = this.createPayload();
            const payload = { ...this.newsletter };
            this.$emit("submit-form", payload);
        },

        transformItemToEdit() {
            this.newsletter.title = this.newsletterToEdit.title;
            this.newsletter.date = this.newsletterToEdit.date;
            this.newsletter.link = this.newsletterToEdit.old_site_url;

            // this.newsletterToEdit.sections.forEach((item) => {
            //     this.menuItems.push(item.title);
            //     this.contentItems.push(item.content);
            // });

            this.activeMenu = this.menuItems[0];
            this.activeContent = this.contentItems[0];

            this.activeIdx = 0;
        },

        handleDragStart(e) {
            this.contentItems[this.activeIdx] = this.activeContent;
            this.activeIdx = e.oldIndex;
        },

        handleDraggableChange(e) {
            if (this.activeIdx !== null) {
                this.contentItems[this.activeIdx] = this.activeContent;
            }
            const oldIndex = e.moved.oldIndex;
            const newIndex = e.moved.newIndex;

            const tempArray = [...this.contentItems];
            const tempItem = tempArray[oldIndex];

            tempArray.splice(oldIndex, 1);

            tempArray.splice(newIndex, 0, tempItem);

            this.contentItems = [...tempArray];
            this.activeIdx = newIndex;
        },
    },

    watch: {
        activeIdx(newVal) {
            this.activeMenu = this.menuItems[newVal];
            this.activeContent = this.contentItems[newVal];
        },
        newsletter: {
            handler(newVal) {
                this.$v.$reset();
            },
            deep: true,
        }
    },

    created() {
        if (this.newsletterToEdit) {
            this.transformItemToEdit();
        }
    },
};
</script>

<style scoped>
.side__menu {
    max-height: 390px;
    overflow: auto;
}
.side__menu-item {
    background-color: var(--v-primary-base);
}
.side__menu-item:hover {
    cursor: pointer;
    background-color: grey;
}

.side__menu-item.active {
    background-color: grey;
}

#newsletterEditor {
    overflow-y: auto;
}

.website-link-hover:hover {
    color: var(--v-purple-base) !important;
}
</style>
