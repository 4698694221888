<template>
    <div class="loading__spinner">
        <v-progress-circular
            :size="50"
            :color="color"
            indeterminate
        ></v-progress-circular>
    </div>
</template>

<script>
export default {
    name: "LoadingSpinner",

    props: {
        color: {
            type: String,
            required: false,
            default: "primary",
        },
    },
};
</script>

<style scoped>
.loading__spinner {
    height: 100%;
    width: 100%;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
